import { Box, Image, ResponsiveContext } from "grommet";
import React, { Fragment } from "react";

import MobilePageHeader from "./MobilePageHeader";
import PageHeader from "./PageHeader";

interface PageContainerProps {
  label: string;
  width?: string;
  headerButton?: JSX.Element;
  headerChildren?: JSX.Element;
  children?: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = ({
  label,
  width,
  headerButton,
  headerChildren,
  children,
}) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Fragment>
      {"xsmall" === size && (
        <MobilePageHeader
          label={label}
          button={headerButton}
          children={headerChildren}
        />
      )}
      <Box flex overflow={"auto"}>
        {"large" === size && (
          <Image
            src="/logo.png"
            height={40}
            width={120}
            style={{ position: "absolute", top: "30px", right: "35px" }}
          />
        )}
        <Box width={width} pad={{ vertical: "small" }} alignSelf="center">
          {"xsmall" !== size && (
            <PageHeader label={label} showIcon={"large" !== size} />
          )}
          {children}
        </Box>
      </Box>
    </Fragment>
  );
};

export default PageContainer;
