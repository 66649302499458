import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { fetchAuthType } from "@Api/auth";
import { AuthType } from "@Api/bookingTypes";
import { fetchPortalSettings } from "@Api/filter";

import Login from "@Components/Login";
import LoginBankID from "@Components/Login/LoginBankID";

import { setPortalSettings } from "@Hooks/useBooking/actions";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";

import messages from "./messages";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const bookingDispatch = useBookingAction();
  const errorDispatch = useErrorAction();
  const [authType, setAuthType] = useState(AuthType.PLAIN);

  useEffect(() => {
    let isCancelled = false;
    fetchAuthType()
      .then((type) => {
        if (!isCancelled) {
          setAuthType(type);
        }
      })
      .catch(() => {
        errorDispatch(
          pushErrorAction({
            title: formatMessage(messages.noAuthTypeTitle),
            message: formatMessage(messages.noAuthTypeMessage),
            fixed: true,
          })
        );
      });
    return () => {
      isCancelled = true;
    };
  }, [errorDispatch, formatMessage]);

  useEffect(() => {
    let isCancelled = false;
    fetchPortalSettings()
      .then((settings) => {
        if (!isCancelled) {
          bookingDispatch(setPortalSettings(settings));
        }
      })
      .catch(() => {
        errorDispatch(
          pushErrorAction({
            title: formatMessage(messages.loadingErrorTitle),
            message: formatMessage(messages.loadingErrorMessage),
          })
        );
      });
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, formatMessage]);

  return authType === AuthType.FED ? <LoginBankID /> : <Login />;
};

export default LoginPage;
