import { defineMessages } from "react-intl";

export default defineMessages({
  select: "Välj",
  noSearch:
    "Sök fram tider med hjälp av menyn till vänster. Välj en tid för att skapa eller omboka.",
  noResult: "Inga lediga tider tillgängliga för valda sökalternativ.",
  noResultTryAgain:
    "Bokningsbara tider publiceras löpande, prova gärna igen senare.",
  hasMorHits:
    "Sökningen gav för många träffar, ändra sökalternativen för ett mer anpassat urval.",
  searching:
  "Söker tider...",
});
