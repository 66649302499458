import { Company, CompanySettings, Patient } from "./bookingTypes";

export interface InitiateResponse {
  sessionId: string;
  redirectUrl: string;
}

export interface CompleteResponse {
  status: BankIdStatus;
  errorMessage?: string;
  patient?: Patient;
  companySettings?: CompanySettings;
}

export interface LoginResponse {
  patient: Patient;
  company?: Company;
}

export enum BankIdStatus {
  PENDING = "pending",
  OK = "ok",
  ERROR = "error",
}
