import axios from "axios";

import { ApplicationInfo } from "./applicationInfoTypes";

export const fetchApplicationInfo = async (): Promise<ApplicationInfo> => {
  const result = await axios.get("/application-info.json", {
    validateStatus: (status) => status === 200,
  });

  return result.data;
};
