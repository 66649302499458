import axios from "axios";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/sv";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import "typeface-montserrat";
import "typeface-roboto";

import NotFound from "@Components/Login/NotFound";

import {
  clearPatient,
  setBookingId,
  setClientId,
} from "@Hooks/useBooking/actions";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";

import BookPage from "@Pages/BookPage";
import ConfirmationPage from "@Pages/ConfirmationPage";
import LoginPage from "@Pages/LoginPage";

interface RoutesProps {
  setLanguage?: (language: string) => void;
  language: string;
}

const Routes: React.FC<RoutesProps> = ({ language }) => {
  moment.locale(language);
  const { patientDetails } = useBookingState();
  const bookingDispatch = useBookingAction();
  const [loadedClientId, setLoadedClientId] = useState(false);
  const [loadedBookingId, setLoadedBookingId] = useState(false);
  const [loadedInterceptor, setLoadedInterceptor] = useState(false);
  const isAuthenticated = !!patientDetails?.civicRegNumber;
  const location = useLocation();

  const navigateProps = (pathname: string) => ({
    to: {
      pathname: pathname,
      search: location ? location.search : "",
    },
    state: { from: location },
  });

  useEffect(() => {
    let isCancelled = false;
    const path = location.pathname;
    const pathArr = path.split("/").filter((s) => s !== "");
    if (pathArr.length === 2 && pathArr[0] === "book") {
      const bookingId = pathArr[1];
      if (bookingId && !isCancelled) {
        bookingDispatch(setBookingId(bookingId));
      }
    }
    setLoadedBookingId(true);
    return () => {
      isCancelled = true;
    };
  }, [location, bookingDispatch]);

  useEffect(() => {
    let isCancelled = false;
    const client_id = new URLSearchParams(location.search).get("client_no");
    if (client_id && !isCancelled) {
      bookingDispatch(setClientId(client_id));
    }
    setLoadedClientId(true);
    return () => {
      isCancelled = true;
    };
  }, [location, bookingDispatch]);

  useEffect(() => {
    let isCancelled = false;
    axios.interceptors.response.use(
      (response) => {
        if (!isCancelled && response.status === 401) {
          bookingDispatch(clearPatient());
        }
        return response;
      },
      (error) => {
        if (!isCancelled && error.response.status === 401) {
          bookingDispatch(clearPatient());
        }
        return Promise.reject(error);
      }
    );
    setLoadedInterceptor(true);
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch]);

  if (!(loadedClientId && loadedInterceptor && loadedBookingId)) {
    return null;
  }

  const previousOrDefault = (location.state?.from ?
      <Navigate {...{ to: { ...location.state.from }}} /> : <Navigate to="/" />
  );

  return (
    <Switch>
      <Route
        path="/login"
        element={
          isAuthenticated ? previousOrDefault : <LoginPage />
        }
      />
      <Route
        path="/book/:id"
        element={
          isAuthenticated ? (
            <ConfirmationPage />
          ) : (
            <Navigate {...navigateProps("/login")} />
          )
        }
      />
      <Route
        path="/book"
        element={
          isAuthenticated ? (
            <BookPage />
          ) : (
            <Navigate {...navigateProps("/login")} />
          )
        }
      />
      <Route path="/" element={<Navigate {...navigateProps("/book")} />} />
      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
