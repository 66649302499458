import { Grommet } from "grommet";
import React from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";

import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import theme from "./style/theme";

const Root = () => {
  return (
    <Grommet theme={theme} full>
      <App />
    </Grommet>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
