import {
  Box,
  Form,
  FormField,
  Heading,
  Image,
  ResponsiveContext,
  ThemeContext,
} from "grommet";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { bankIdComplete, bankIdInitiate } from "@Api/auth";
import { BankIdStatus } from "@Api/authTypes";

import { setCompanySettings, setPatient } from "@Hooks/useBooking/actions";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";
import { useInterval } from "@Hooks/useInterval/useInterval";

import BankIdIcon from "@Resources/img/BankID.png";
import IconLogin from "@Resources/img/icon-login.png";

import theme from "@Style/theme";

import Button from "../Common/Button";
import Spinner from "../Common/Spinner";
import Version from "./Version";
import { getLoginErrorMessage } from "./common";
import messages from "./messages";

interface Props {}

const LoginPage = styled(Box)`
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const LoginWrapper = styled(Box)`
  width: 438px;
  height: 100%;

  & h1 {
    color: ${({ theme }) => theme.global.colors.headerText};
  }
`;

const LoginBox = styled(Box)`
  background: ${({ theme }) => theme.global.colors.backgroundLight};
  border: 1px solid ${({ theme }) => theme.global.colors.infoBoxBorder};
  border-radius: 9px;
  margin: 5px 0;
  width: 100%;
  min-height: 400px;
  max-height: 500px;
  flex: 1;
  flex-direction: column;
`;

const LoginFormField = styled(FormField)`
  width: 248px;
  height: 134px;
  margin-top: 30px;
  margin-bottom: 0;
  & > div {
    border-color: ${({ theme }) => theme.global.colors.headerText};
  }
  & > input ::-webkit-input-placeholder {
    color: red;
  }
  & > span {
    text-align: center;
    margin-right: -16px;
    margin-left: -16px;
  }
  color: ${({ theme }) => theme.global.colors.headerText};
`;

const LoginBankID: React.FC<Props> = () => {
  const bookingDispatch = useBookingAction();
  const { bookingId, clientId } = useBookingState();
  const { formatMessage } = useIntl();
  const [error, setError] = useState("");
  const [civicRegistrationNumber, setCivicRegistrationNumber] = useState("");
  const [
    civicRegistrationNumberWithoutHyphen,
    setCivicRegistrationNumberWithoutHyphen,
  ] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [pollCount, setPollCount] = useState(0);
  const [redirectUri, setRedirectUri] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const errorDispatch = useErrorAction();

  if (!(bookingId || clientId)) {
    errorDispatch(
      pushErrorAction({
        title: formatMessage(messages.noCustomerNoTitle),
        message: formatMessage(messages.noCustomerNoMessage),
        fixed: true,
      })
    );
  }

  const initiate = async () => {
    if (!civicRegistrationNumber.match(/^\d{8}[-|(\s)]{0,1}\d{4}$/)) {
      setError(formatMessage(messages.pnrMissing));
      return;
    }

    bankIdInitiate(civicRegistrationNumberWithoutHyphen, clientId, bookingId)
      .then((response) => {
        setSessionId(response.sessionId);
        setPollCount(0);
        setRedirectUri(response.redirectUrl);
      })
      .catch((reason) => {
        setError(formatMessage(messages.loginFailed));
        setSessionId("");
      });
  };

  useEffect(() => {
    setCivicRegistrationNumberWithoutHyphen(
      civicRegistrationNumber.replace("-", "")
    );
  }, [civicRegistrationNumber]);

  useInterval(() => {
    if (sessionId) {
      setPollCount(pollCount + 1);
      if (pollCount > 60) {
        setSessionId("");
        setError(formatMessage(messages.loginFailed));
      }
      bankIdComplete(
        civicRegistrationNumberWithoutHyphen,
        sessionId,
        redirectUri,
        clientId,
        bookingId
      )
        .then((response) => {
          if (response.status === BankIdStatus.OK && response.patient) {
            bookingDispatch(setPatient(response.patient));
            if (response.companySettings) {
              bookingDispatch(setCompanySettings(response.companySettings));
            }
          }
        })
        .catch((reason) => {
          setError(
            getLoginErrorMessage(
              reason?.response?.data["message"],
              formatMessage
            )
          );
          setSessionId("");
          setRedirectUri("");
        });
    }
  }, 3000);
  const size = React.useContext(ResponsiveContext);
  const bankidMargin =
    "xsmall" === size ? "13px" : "small" === size ? "18px" : "28px";
  return (
    <ThemeContext.Extend
      value={{
        global: { colors: { placeholder: theme.global.colors.brand } },
      }}>
      <LoginPage flex>
        <LoginWrapper>
          <Box
            style={{ flex: 1, maxHeight: "120px", minHeight: "55px" }}
            direction={"row"}>
            {("xlarge" === size || "large" === size || "medium" === size) && (
              <Image
                src="/logo.png"
                height={40}
                width={120}
                style={{ position: "absolute", top: "20px", right: "35px" }}
              />
            )}
            <Heading alignSelf={"end"} level="1" margin={{ vertical: "small" }}>
              {formatMessage(messages.header)}
            </Heading>
          </Box>
          <LoginBox
            alignSelf={"center"}
            pad={{ horizontal: "medium" }}
            justify="center"
            align={"center"}
            style={{ position: "relative" }}>
            <Image
              src={BankIdIcon}
              height={30}
              width={32}
              style={{
                position: "absolute",
                bottom: bankidMargin,
                right: bankidMargin,
              }}
            />
            <Image src={IconLogin} />
            {sessionId ? (
              <Box pad={"medium"} gap={"medium"}>
                <Spinner color={theme.global.colors.text} size={35} />
                <Heading level={2} alignSelf={"center"} textAlign={"center"}>
                  {formatMessage(messages.waitingForBankId)}
                </Heading>
              </Box>
            ) : (
              <Form onSubmit={initiate}>
                <LoginFormField
                  autoFocus
                  label={formatMessage(messages.label)}
                  value={civicRegistrationNumber}
                  name="pnr"
                  type="text"
                  placeholder={formatMessage(messages.placeholder)}
                  onChange={(event: any) => {
                    setCivicRegistrationNumber(event.target.value);
                  }}
                  error={error}
                />
                <Box pad={{ horizontal: "medium" }} align={"center"}>
                  <Button
                    width={"200px"}
                    height={"36px"}
                    primary
                    label={formatMessage(messages.buttonForBankId)}
                    type="submit"
                  />
                </Box>
              </Form>
            )}
          </LoginBox>
        </LoginWrapper>
        <Version />
      </LoginPage>
    </ThemeContext.Extend>
  );
};

export default LoginBankID;
