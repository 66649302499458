import { Image } from "grommet";
import React from "react";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";

import { SelectOption, SelectServiceOption } from "@Hooks/useBooking/types";

import IndicatorDown from "@Resources/img/icon-arrow-down-white.png";
import IndicatorUp from "@Resources/img/icon-arrow-up-white.png";

const CustomSelect: any = styled(ReactSelect)`
  & .Select__control {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    background: ${({ theme }) => theme.custom.menu.background};
    color: ${({ theme }) => theme.custom.menu.text};
  }
  & .Select__placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme }) => theme.global.colors.placeholder};
  }
  & .Select__menu {
    border-radius: 0px;
    margin: 0px;
  }
  & .Select__multi-value {
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.custom.menu.text};
  }
  & .Select__multi-value__label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    border-radius: 0px;
    background: ${({ theme }) => theme.custom.menu.background};
    color: ${({ theme }) => theme.custom.menu.text};
  }
  & .Select__single-value {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    border-radius: 0px;
    background: ${({ theme }) => theme.custom.menu.background};
    color: ${({ theme }) => theme.custom.menu.text};
  }
  & .Select__indicator-separator {
    background-color: ${({ theme }) => theme.custom.menu.background};
  }
  & .Select__indicator {
    padding: 9px;
    color: ${({ theme }) => theme.custom.menu.text};
  }

  & .Select__multi-value__remove {
    border-radius: 0px;
    background: ${({ theme }) => theme.custom.menu.background};
    color: ${({ theme }) => theme.custom.menu.text};
  }
  & .Select__menu {
    color: ${({ theme }) => theme.custom.menu.background};
  }
  & .Select__option--is-focused {
    background: rgba(221, 221, 221, 0.4);
  }
  & .Select__option--is-selected {
    background: ${({ theme }) => theme.global.colors.brand};
  }
  & .Select__input {
    color: ${({ theme }) => theme.custom.menu.text};
  }
`;

interface Props {
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  menuPlacement?: string;
  value?: SelectOption | SelectOption[] | SelectServiceOption | null;
  onChange: (
    selected?: SelectOption | SelectOption[] | SelectServiceOption | null
  ) => void;
  options: SelectOption[] | SelectServiceOption[];
  placeholder?: string;
  noOptionsMessage?: string;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
      <Image
        width={"16px"}
        height={"15px"}
        src={props.selectProps.menuIsOpen ? IndicatorUp : IndicatorDown}
        hidden={props.isDisabled}
      />
      </components.DropdownIndicator>
    )
  );
};

const SearchSelect: React.FC<Props> = (props) => {
  if (isSelectOptionArray(props.options)) {
    props.options.sort((a, b) => a.label.localeCompare(b.label, 'sv'));
  }

  return (
    <CustomSelect
      components={{ DropdownIndicator }}
      classNamePrefix={"Select"}
      {...props}
      noOptionsMessage={
        props.noOptionsMessage === undefined
          ? undefined
          : () => {
              return props.noOptionsMessage;
            }
      }
    />
  );
};

function isSelectOptionArray(options: any[]): options is SelectOption[] {
  return options.length > 0 && 'label' in options[0];
}

export default SearchSelect;
