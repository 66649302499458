import { defineMessages } from "react-intl";

export default defineMessages({
  header: "Tidbokning",
  label: "Ange personnummer *",
  placeholder: "ååååmmddnnnn",
  buttonForBankId: "Logga in",
  button: "Gå vidare",
  waitingForBankId: "Starta BankID på din telefon eller dator",
  pnrMissing: "Ange personnummer i formatet ååååmmddnnnn för att logga in.",
  loginFailed: "Inloggning misslyckades",
  noCustomerNoTitle: "Ogiltig sökväg angiven",
  noCustomerNoMessage:
    "Ogiltig sökväg angiven. Vänligen kontakta din mottagning för att få korrekt länk till den webbaserade tidbokningen.",
  patientMismatch:
    "Personnummer på bokning stämmer inte med angivet personnummer",
  bookingMissing: "Bokningen kan inte hittas, kontakta din mottagning",
  notFoundMessage: "Sidan eller bokningen kunde inte hittas",
  noPatientOnBooking:
    "Bokningen kan inte om- eller avbokas via tidboken, vänligen kontakta din mottagning",
});
