import { Main, ResponsiveContext } from "grommet";
import "moment/locale/en-gb";
import "moment/locale/sv";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import "typeface-montserrat";
import "typeface-roboto";
import ErrorModal from "@Components/ErrorModal";
import BookingProvider from "@Context/BookingProvider";
import ErrorProvider from "@Context/ErrorProvider";
import Routes from "./Routes";

export const domFloodProtectionSlotLimit = 150;

const App = () => {
  const [language, setLanguage] = useState("sv");
  const [messages, setMessages] = useState({});
  const [loaded, setLoaded] = useState(false);

  const size = React.useContext(ResponsiveContext);

  const small = "xsmall" === size || "small" === size;
  const background = small
    ? undefined
    : {
        image: "url(/background.jpg)",
        repeat: "no-repeat",
        position: "initial",
        size: "100px",
      };

  useEffect(() => {
    let isCancelled = false;

    fetch(`/i18n/${language}.json`).then((response) => {
      response.json().then((localizedMessages: Record<string, string>) => {
        if (!isCancelled) {
          setMessages(localizedMessages);
          setLoaded(true);
        }
      });
    });

    return () => {
      isCancelled = true;
    };
  }, [language]);

  if (!loaded) {
    return null;
  } else {
    return (
      <IntlProvider locale={language} key={language} messages={messages}>
        <ErrorProvider>
          <BookingProvider>
            <Main background={background}>
              <Router>
                <Routes language={language} setLanguage={setLanguage} />
              </Router>
              <ErrorModal />
            </Main>
          </BookingProvider>
        </ErrorProvider>
      </IntlProvider>
    );
  }
};

export default App;
